import { companyProjectRoleClient } from "@/api/contributors";
import { CompanyProjectRole } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {
  companyProjectRoles: () => CompanyProjectRole.query().with("contributors").with("company").with("role").all(),
};

const actions = {
  async createCompanyProjectRole(context, companyProjectRole) {
    const companyProjectRoleData = await companyProjectRoleClient.create(
      companyProjectRole
    );

    await CompanyProjectRole.create(
      {data: companyProjectRoleData}
    );

    return companyProjectRoleData;
  },

  updateCompanyProjectRole: async (context, companyProjectRole) => {
    const companyProjectRoleData = await companyProjectRoleClient.update(
      companyProjectRole
    );

    await CompanyProjectRole.update({
      data: [companyProjectRoleData],
    });

    return companyProjectRoleData;
  },

  async destroyCompanyProjectRole(context, companyProjectRole) {
    await companyProjectRoleClient.destroy(companyProjectRole);

    await CompanyProjectRole.delete(companyProjectRole.uuid);
  },

  async fetchCompanyProjectRoles(context, metadata = {}) {
    const companyProjectRolesData = await companyProjectRoleClient.list(metadata);

    CompanyProjectRole.deleteAll();

    const { companyProjectRole } = await CompanyProjectRole.insert(
      { data: companyProjectRolesData }
    );

    return companyProjectRole;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
